<template>
  <div style='max-width:500px; margin:auto;'>
    <div class="row pt-4 text-center align-items-center justify-content-center" >
      <h5 class="header-text pt-4"> Ожидайте, мы проверяем вашу транзакцию </h5>
 
    </div>
    <div class="mx-4 mt-3 pb-0 mb-4 card card-body my-inner-card text-center align-items-center justify-content-center">
      <h5 class="header-text "> {{timer_value}}</h5>
      <p class="label">Обычно проверка идет в течении 5 минут</p>
    </div>

    <div class="mx-4 card card-body my-inner-card ">
          <dt class="col-10 mt-2 left-text strong">Информация о платеже</dt>
        <dl class="row">
          <dt class="col-4 mt-2 left-text">Сумма</dt>
          <dd class="col-8 mt-2 right-text">{{paymentDetail.value.toLocaleString('ru')}} руб.</dd>
          <dt class="col-4 mt-2 left-text" v-if="paymentDetail.type_payment === 'card'">Номер карты</dt>
          <dt class="col-4 mt-2 left-text" v-else>Номер телефонв</dt>
          <dd class="col-8 mt-2 right-text">  {{paymentDetail.merchant_account.address}} </dd>
          <dt class="col-4 mt-2 left-text">ФИО</dt>
          <dd class="col-8 mt-2 right-text" >{{paymentDetail.merchant_account.fio}}</dd>
          <dt class="col-4 mt-2 left-text">Название банка</dt>
          <dd class="col-8 mt-2 right-text">{{paymentDetail.merchant_account.bank.name}}</dd>
        </dl>
    </div>

    <div class="mx-4 mt-3 pb-0 mb-4 card card-body my-inner-card text-center align-items-center justify-content-center">
      <p class="label">После оплаты обязательно отправьте чек (квитанцию) менеджеру, с которым вы ведете общение.</p>
    </div>
    
    <div class='row fixed-btn'>
      <!-- <button
        class="btn bg-light text-dark"
        @click="need_help()"
        type="button"
        >
          <i class="fas fa-user " aria-hidden="true"></i>
        
          <span class="p-2">Помощь</span>  
        </button
      >
      <button
        class="btn bg-dark text-light"
        @click="back_to_lk()"
        type="button"
        >
          <span class="p-2 strong">Вернуться в личный кабинет</span>  
        </button
      > -->

    </div>

    
</div>

   
</template>

<style scoped>

.right-text{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  /* or 229% */

  align-items: right;
  text-align: right;
  
  color: #fff;
}
.strong{
  font-weight: 900 !important;
  color: #fff !important;
}
.left-text{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.79);;
}

.my-inner-card{
  background: #7b7b7b26;
  border-radius: 12px;
  color:#fff;
  box-shadow:none;
}
.my-input{
  border-color: 1px solid #d2d6da;
  background-color:#fff !important;
  padding-right: unset;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}
.logo-header{
   width: 60%;
   height: auto;
   margin-left: auto;
   margin-right: auto;
   margin-top:15px;
   margin-bottom:40px;
}
.text-description{
  width:80%;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #EBEFF3;
}
.robot-l{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22' %3e%3cpath fill='%23d2d6da' d='M21.8966 14.6666L19.4139 9.1319C19.1379 8.57847 18.7243 8.30167 18.1724 8.30167H17.2069C17.069 7.60993 16.7931 6.91807 16.3793 6.36464L20.2413 2.49058C20.793 1.93716 20.793 1.10698 20.2413 0.553551C19.6896 0.000125978 18.862 0.000125978 18.3103 0.553551L14.4482 4.42761C12.2413 3.04401 9.48262 3.04401 7.41372 4.42761L3.68956 0.415069C3.13785 -0.138356 2.31025 -0.138356 1.75854 0.415069C1.20683 0.968494 1.20683 1.79867 1.75854 2.3521L5.4827 6.22633C5.20693 6.77975 4.93099 7.47149 4.7931 8.16336H3.82757C3.13781 8.16336 2.72403 8.57846 2.44826 8.99358L0.103414 14.5283C-0.0344713 14.9434 -0.0344713 15.3585 0.103414 15.6351L2.44826 21.1698C2.72419 21.7232 3.13785 22 3.68969 22H18.1724C18.7242 22 19.2759 21.7232 19.4139 21.1698L21.8966 15.6351C22.0345 15.3583 22.0345 14.9432 21.8964 14.6666H21.8966ZM11.0002 5.94978C12.5174 5.94978 13.7589 6.91832 14.4485 8.16361H7.55211C8.2417 6.91832 9.48313 5.94978 11.0004 5.94978H11.0002ZM7.41393 16.7419C6.58628 16.7419 6.03461 16.1885 6.03461 15.3583C6.03461 14.5281 6.58632 13.9747 7.41393 13.9747C8.24157 13.9747 8.79324 14.5281 8.79324 15.3583C8.79324 16.1885 8.24153 16.7419 7.41393 16.7419ZM14.5866 16.7419C13.759 16.7419 13.2073 16.1885 13.2073 15.3583C13.2073 14.5281 13.759 13.9747 14.5866 13.9747C15.4143 13.9747 15.9659 14.5281 15.9659 15.3583C15.9659 16.1885 15.2762 16.7419 14.5866 16.7419Z'/%3e%3c/svg%3e");

}


.email-l{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 20' %3e%3cpath fill='%23d2d6da' d='M26 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H26C26.5304 20 27.0391 19.7893 27.4142 19.4142C27.7893 19.0391 28 18.5304 28 18V2C28 1.46957 27.7893 0.960859 27.4142 0.585786C27.0391 0.210714 26.5304 0 26 0ZM23.8 2L14 8.78L4.2 2H23.8ZM2 18V2.91L13.43 10.82C13.5974 10.9361 13.7963 10.9984 14 10.9984C14.2037 10.9984 14.4026 10.9361 14.57 10.82L26 2.91V18H2Z'/%3e%3c/svg%3e");
}
.value{
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 16px;
  color: #000000;
}
.label{
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 167% */

  text-align: center;

  color: #FFFFFF;
}
.bg-my-light{
  color: #BBBFC5;
  background: #EBEFF3;
  border-radius: 12px;
  box-shadow: none;
}
.bg-my-info{
  background: #1d1d1d;
  border-radius: 8px;
  box-shadow: none;
  color: #FFFFFF;
}
.btn:hover{
  color: none;
}
.paragraph{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.episod{

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  color: #000000;
}
.fixed-btn{
    width: 100%;
    padding: 20px;
    margin: 0px;


}
.header-text{
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;

}

</style>
<script>

import { mapMutations,mapActions,mapGetters } from "vuex";
export default {
  name: "s1",
  components: {    
  },
  data() {
    return {
      errors:{},
      data:{},
      loading: false,
      
    };
  },
  created() {
    this.startRepetedUpdateStatus()
  },
  beforeUnmount() {
  },
  computed:{
    ...mapGetters(["counter","paymentDetail"]),
    timer_value(){
      
      var m = Math.trunc(this.counter / 60)
      var h = Math.trunc(this.counter / 3600)
      var s = this.counter - m * 60
      return ('0' + h).slice(-2) +':'  +('0' + m).slice(-2) + ':' + ('0' + s).slice(-2)
      
    }
  },
  unmounted() {
      this.root.style.setProperty("--bs-body-bg", "#fff");
    },
  mounted() {
      this.root = document.documentElement;
      this.root.style.setProperty("--bs-body-bg", "#17191E");
    },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["updateStatus"]),
    next(){
        this.$store.state.step+=1
    },
    back_to_lk(){
      location.href = 'https://monetndvor.com/'
    },
    regect(){
        location.href = '/'
    },
    need_help(){
      location.href = `tg://resolve?domain=help_Mint_support?uid=${this.$store.state.uid}`
    },
    async startRepetedUpdateStatus() {
      while (this.$store.state.counter < 30 * 6000 ) {
        await new Promise(resolve => setTimeout(resolve, 10000));
          this.updateStatus()
      }

    },

  },
};
</script>
