<template>
  <div  v-if="paymentDetail.status" style="top:0; min-height:100vh;" :class="{'bg-dark-blue':step===5 || step===8 || step===9, 'my-bg-info':step===7}">
    <template v-if="step===1">
      <step1-mobile  />
    </template>

     <template v-if="step===2">
      <step2-mobile v-if="isMobile()" />
      <step2-desktop v-else />
    </template>

    <template v-if="step===3">
      <step3-mobile v-if="isMobile()" />
      <step3-desktop v-else />
    </template>

     <template v-if="step===4">
      <step4-mobile  />
      <!-- <step4-desktop v-else /> -->
    </template>

    <template v-if="step===5">
      <step5-mobile  />
    </template>

    <template v-if="step===6">
      <step6-mobile />
    </template>

    <template v-if="step===7">
      <step7-mobile v-if="isMobile()" />
      <step7-desktop v-else />
    </template>
    <template v-if="step===8">
      <step8-mobile v-if="isMobile()" />
      <step8-desktop v-else />
    </template>

     <template v-if="step===9">
      <step9-mobile />
    </template>


  </div>
  <div   v-else style="top:0; min-height:100vh;" class="center">
    <loader ></loader>
  </div>
</template>
<style scoped>

.bg-dark-blue{
  background: #17191E;
}
.center {
          display: flex;
          height:100vh;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
</style>
<script>
import loader from "./components/loader.vue";
import step1Mobile from "./p2pline/mobile/s1.vue";
import step2Mobile from "./p2pline/mobile/s2.vue";
import step3Mobile from "./p2pline/mobile/s3.vue";
import step4Mobile from "./p2pline/mobile/s4.vue";
import step5Mobile from "./p2pline/mobile/s5.vue";
import step6Mobile from "./p2pline/mobile/s6.vue";
import step7Mobile from "./p2pline/mobile/s7.vue";
import step8Mobile from "./p2pline/mobile/s8.vue";
import step9Mobile from "./p2pline/mobile/s9.vue";

// import step1Desktop from "./p2pline/desktop/s1.vue";
import step2Desktop from "./p2pline/desktop/s2.vue";
import step3Desktop from "./p2pline/desktop/s3.vue";
// import step4Desktop from "./p2pline/desktop/s4.vue";
// import step5Desktop from "./p2pline/desktop/s5.vue";
// import step6Desktop from "./p2pline/desktop/s6.vue";
import step7Desktop from "./p2pline/desktop/s7.vue";
import step8Desktop from "./p2pline/desktop/s8.vue";
// import step9Desktop from "./p2pline/desktop/s9.vue";

import { mapMutations,mapGetters,mapActions  } from "vuex";

export default {
  name: "p2p",
  components: {
    step1Mobile,
    step2Mobile,
    step3Mobile,
    step4Mobile,
    step5Mobile,
    step6Mobile,
    step7Mobile,
    step8Mobile,
    step9Mobile,
    // step1Desktop,
    step2Desktop,
    step3Desktop,
    // step4Desktop,
    // step5Desktop,
    // step6Desktop,
    step7Desktop,
    step8Desktop,
    // step9Desktop,
    loader
    // NavPill,
    // MasterCard,
    // DefaultInfoCard,
    // PaymentMethodsCard,
    // InvoiceCard,
    // BillingCard,
    // SoftBadge,
    // RankingList,
    // RankingListCard,
  },
 
  data() {
    return {
      startedCounter: false,
    };
  },
  watch: {
    paymentDetail(val) {
      if(val.status=='process') this.$store.state.step=6
      if(val.status=='paid') this.$store.state.step=7
      if(val.status=='approved') this.$store.state.step=8
      if(val.status=='rejected') this.$store.state.step=9
      this.$store.state.counter = val.time_window_sec
      if (!this.startedCounter){
        this.startedCounter = true;
        this.startCounter();
      }
        
      
    },
  },
  beforeMount() {
    this.getPaymentDetail(this.$route.query.uid);
    this.getPaymentMethods()
  },
  computed:{
     ...mapGetters(["step",'paymentDetail','replain']),
  },
  


  methods: {
    ...mapMutations(["toggleConfigurator",]),
    ...mapActions(["getTransactions","getPaymentMethods","getPaymentDetail"]),
    async startCounter() {
      while (this.$store.state.counter < 30 * 6000 ) {
        await new Promise(counteer => setTimeout(counteer, 1000));
        this.$store.state.counter++;
      }

    },
    

    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>
